import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { auth } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClasesService } from '../services/clases.service';
@Component({
  selector: 'app-loginmicrosoft',
  templateUrl: './loginmicrosoft.component.html',
  styleUrls: ['./loginmicrosoft.component.css'],
})
export class LoginmicrosoftComponent implements OnInit {
  email: string = null;
  password: string = null;
  userdata: any;
  remember = false;
  usuario: any;
  lang: string;

  es: boolean = false;
  en: boolean = false;
  ar: boolean = false;
  idioma: boolean = false;
  click: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private afAuth: AngularFireAuth,
    private clasesService: ClasesService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('user')) {
      this.usuario = localStorage.getItem('user');
    }

    if (localStorage.getItem('passw')) {
      this.password = atob(localStorage.getItem('passw'));
    }
    if (localStorage.getItem('idioma')) {
      this.lang = localStorage.getItem('idioma');
      this.translate.setDefaultLang(this.lang);
      this.translate.use(this.lang);
      switch (this.lang) {
        case 'es':
          this.es = true;
          break;
        case 'en':
          this.en = true;
          break;
        case 'ar':
          this.ar = true;
          break;
        default:
          break;
      }
    } else {
      this.es = true;
      this.translate.setDefaultLang('es');
      this.translate.use('es');
      localStorage.setItem('idioma', 'es');
    }
  }

  // loginInSAMLmicrosoft() {
  //   this.authenticationService
  //     .loginWithMicrosoft()
  //     .then((result) => {
  //       console.log('User signed in:', result);
  //       console.log('aqui1');
  //       this.userService.getUserById(result.uid).subscribe((ddata) => {
  //         console.log('aqui2');
  //         if (ddata[0]) {
  //           this.userdata = ddata[0].payload.doc.data();
  //           this.userService.setUserinfo(ddata[0]);

  //           if (
  //             this.userdata.rol === 'estudiante' ||
  //             this.userdata.rol === 'profesor' ||
  //             this.userdata.rol === 'director' ||
  //             this.userdata.rol === 'supervisor'
  //           ) {
  //             this.router.navigate(['dashboard']);
  //           } else if (this.userdata.rol === 'admin') {
  //             this.router.navigate(['admin']);
  //           }
  //         } else {
  //           console.log(result.email);
  //           this.userService.getUserByEmail(result.email).subscribe((ddata) => {
  //             if (ddata[0]) {
  //               this.userdata = ddata[0].payload.doc.data();
  //               this.userdata.uid = result.uid;
  //               this.userService.setUserinfo(ddata[0]);
  //               this.userService.editUser(
  //                 this.userdata,
  //                 ddata[0].payload.doc.id
  //               );
  //               this.clasesService
  //                 .addUsuarioToClaseGrupoCorporativo(result.uid)
  //                 .then(() => {
  //                   if (
  //                     this.userdata.rol === 'estudiante' ||
  //                     this.userdata.rol === 'profesor' ||
  //                     this.userdata.rol === 'director' ||
  //                     this.userdata.rol === 'supervisor'
  //                   ) {
  //                     this.router.navigate(['dashboard']);
  //                   } else if (this.userdata.rol === 'admin') {
  //                     this.router.navigate(['admin']);
  //                   }
  //                 });
  //             } else {
  //               console.log('no data', result);
  //               // const user = {
  //               //   apellidos: result.surname,
  //               //   avatar: '',
  //               //   clases: ['0000QaF2XuNsdzf7hzjd'],
  //               //   codigo: '',
  //               //   colegios: ['0000j595LlYTvhatfHCP'],
  //               //   cursos: {
  //               //     nombre: 'Laia - Gofacing',
  //               //     uid: '0000B5wcydN4eIxQ8C9b',
  //               //   },
  //               //   direccion: 'Av Demo',
  //               //   email: result.email,
  //               //   fechaCheck: new Date(),
  //               //   fechanac: new Date(),
  //               //   fechana: '2000-01-01',
  //               //   genero: 'Masculino',
  //               //   licencia: '',
  //               //   localidad: null,
  //               //   nombres: result.name,
  //               //   padres: [],
  //               //   pais: 'España',
  //               //   rol: 'estudiante',
  //               //   telefono: '',
  //               //   uid: result.uid,
  //               //   usuario: result.email,
  //               // };
  //               // this.userService.createdUser(user).then(() => {
  //               //   this.clasesService
  //               //     .addUsuarioToClaseGrupoCorporativo(result.uid)
  //               //     .then(() => {
  //               //       this.router.navigate(['dashboard']);
  //               //     });
  //               // });
  //             }
  //           });
  //         }
  //       });

  //       //Aqui quiero rescarar el UID para luego hacer un this.userService.getUserById(user.uid)
  //     })
  //     .catch((error) => {
  //       console.error('Error signing in:', error);
  //     });
  // }

  remembercredentials(remember) {
    if (remember) {
      let con = btoa(this.password);
      localStorage.setItem('user', this.usuario);
      localStorage.setItem('passw', con);
    }
  }

  rememberme() {
    if (this.remember == false) {
      this.remember = true;
    } else {
      this.remember = false;
    }
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('idioma', lang);
  }

  espanol() {
    this.translate.use('es');
    this.es = true;
    this.en = false;
    this.ar = false;
    localStorage.setItem('idioma', 'es');
    this.activeLanguage();
  }

  ingles() {
    this.translate.use('en');
    this.en = true;
    this.es = false;
    this.ar = false;
    localStorage.setItem('idioma', 'en');
    this.activeLanguage();
  }

  arabe() {
    this.translate.use('ar');
    this.en = false;
    this.es = false;
    this.ar = true;
    localStorage.setItem('idioma', 'ar');
    this.activeLanguage();
  }

  changeColor() {
    this.click = !this.click;
  }

  activeLanguage() {
    this.idioma ? (this.idioma = false) : (this.idioma = true);
  }
}
