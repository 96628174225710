import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterApp'
})
export class FilterAppPipe implements PipeTransform {

  transform(apps: any[], searchApp: string, selectedCategory: string): any[] {
    if (!apps) return [];
    if (!searchApp && selectedCategory == 'todas') return apps;

    searchApp = searchApp ? searchApp.toLowerCase() : '';
    console.log("apps",apps);
    console.log("categoria",selectedCategory);
    console.log("search",searchApp);
    return apps.filter(app => {
      const matchesSearch = app.nombre.toLowerCase().includes(searchApp);
      const matchesCategory = selectedCategory == 'todas' || app.categoria == selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }

}
