import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class IaFilesService {
  constructor(private firestore: AngularFirestore) {}

  getMessages(chatid) {
    return this.firestore
      .collection('chatsFilesIa')
      .doc(chatid)
      .collection('messages', (ref) => ref.orderBy('fecha', 'asc'))
      .snapshotChanges(['added']);
  }

  sendmessage(id, message) {
    return this.firestore
      .collection('chatsFilesIa')
      .doc(id)
      .collection('messages')
      .add(message);
  }
}
