import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { EventosService } from '../../../services/eventos.service';
import { ClasesService } from '../../../services/clases.service';
import { UserService } from '../../../services/user.service';
import { NotificacionesService } from './../../../services/notificaciones.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css'],
})
export class EventosComponent implements OnInit {
  userUid: any;
  nombre: string;
  descripcion: string;
  clase: any;
  estudiantes = [];
  fechainicio: any;
  horainicio: any;
  fechafinal: any;
  horafinal: any;
  userdata: any;
  clases = [];
  clasesSeleccionadas = [];
  selectclase: any;
  selectuser = [];
  invitados: any = 'clase';
  usuariosparaclase: any;
  clasetoda = [];
  notificacion: any;
  estudianteDatos: any;
  clicked = false;
  ocultarForm = false;
  videollamada: any = 'no';
  linkvideo: string = '';
  emails: any = new Array();
  email: any;
  formatoEmailMensaje: string;
  usuario: any;
  usuariosClasesSeleccionadas: any = [];
  fechaHoy: string = '';
  horaHoy: string;
  ninguna: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventosService: EventosService,
    private clasesService: ClasesService,
    private userService: UserService,
    private notificacionesService: NotificacionesService,
    private functions: AngularFireFunctions,
    private router: Router,
    private breadService: BreadcrumbService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.estudiantes = new Array();
    this.getmainuserdata();
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.getFechaActual();
    this.fechainicio = this.fechaHoy;
    this.fechafinal = this.fechaHoy;
    this.horainicio = this.horaHoy;
    this.horafinal = this.horaHoy;

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid; /* id del usuario */

          this.authenticationService
            .isUserAdmin(this.userUid)
            .subscribe((userdata) => {
              if (userdata[0]) {
                this.userdata = userdata[0];
                this.usuario = this.userdata.usuario;

                this.userdata.clases.forEach((clase) => {
                  this.clasesService.getClaseById(clase).subscribe((tclase) => {
                    this.clases.push(tclase);
                  });
                });
              }
            });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      this.usuario = Userdata.payload.doc.data().usuario;

      this.userdata.clases.forEach((clase) => {
        this.clasesService.getClaseById(clase).subscribe((tclase) => {
          this.clases.push(tclase);
        });
      });
    }
  }

  // selectclass(event) {
  //   this.clasesService.getClaseById(event).subscribe((clase) => {
  //     this.selectclase = clase.payload.data();
  //     this.selectuser = [];
  //     this.clasetoda = [];
  //     this.selectclase.usuarios.forEach((seclase) => {
  //       if (seclase != this.userUid) {
  //         this.userService.getUserById(seclase).subscribe((user) => {
  //           this.selectuser.push(user[0]);
  //           this.usuariosparaclase = user[0].payload.doc.data();
  //           this.clasetoda.push(this.usuariosparaclase.uid);
  //         });
  //       }
  //     });
  //   });
  // }

  // selectinvitados(event) {
  //   this.clasesService.getClaseById(this.clase).subscribe((clase) => {
  //     this.selectclase = clase.payload.data();
  //     this.clasetoda = [];
  //     this.selectclase.usuarios.forEach((seclase) => {
  //       if (seclase != this.userUid) {
  //       this.userService.getUserById(seclase).subscribe((user) => {
  //         this.usuariosparaclase = user[0].payload.doc.data();
  //         this.clasetoda.push(this.usuariosparaclase.uid);
  //       });
  //     }
  //     });
  //   });
  // }

  getFecha() {
    const current = new Date();
    current.setHours(current.getHours());
    current.setMinutes(current.getMinutes());
    current.setMonth(current.getMonth());
    current.setDate(current.getDate());
    return current;
  }

  getFechaActual() {
    var MyDate = this.getFecha();

    this.fechaHoy =
      MyDate.getFullYear() +
      '-' +
      ('0' + (MyDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + MyDate.getDate()).slice(-2);

    this.horaHoy =
      ('0' + MyDate.getHours()).slice(-2) +
      ':' +
      ('0' + MyDate.getMinutes()).slice(-2);
  }

  createevent() {
    this.ocultarForm = true;
    this.notificacion = '';

    let len = localStorage.getItem('idioma');

    if (this.nombre === '' || this.nombre === undefined) {
      this.translate
        .get('dashboard.eventos.notificacion.nombre')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    // if (
    //   (this.clase === '' || this.clase === undefined) &&
    //   this.emails.length == 0
    // ) {
    //   this.notificacion = 'Debe escoger una clase';
    //   setTimeout(() => {
    //     this.ocultarForm = false;
    //   }, 1500);
    //   return;
    // }
    if (this.clasesSeleccionadas.length == 0 && this.emails.length == 0) {
      this.translate
        .get('dashboard.eventos.notificacion.clase')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (
      (this.invitados === '' || this.invitados === undefined) &&
      this.emails.length == 0
    ) {
      this.translate
        .get('dashboard.eventos.notificacion.opcioninvitados')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }
    if (this.estudiantes.length <= 0 && this.emails.length == 0) {
      this.translate
        .get('dashboard.eventos.notificacion.invitados')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.fechainicio === '' || this.fechainicio === undefined) {
      this.translate
        .get('dashboard.eventos.notificacion.fechaini')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.horainicio === '' || this.horainicio === undefined) {
      this.translate
        .get('dashboard.eventos.notificacion.horaini')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.fechafinal === '' || this.fechafinal === undefined) {
      this.translate
        .get('dashboard.eventos.notificacion.fechafin')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.horafinal === '' || this.horafinal === undefined) {
      this.translate
        .get('dashboard.eventos.notificacion.horafin')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.fechafinal < this.fechainicio) {
      this.translate
        .get('dashboard.eventos.notificacion.fechainimenor')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (
      this.fechainicio == this.fechafinal &&
      this.horafinal < this.horainicio
    ) {
      this.translate
        .get('dashboard.eventos.notificacion.fechainidia')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    var fechaActual = this.getFecha();
    var fechaInicialComprobación = new Date(
      this.fechainicio + ' ' + this.horainicio
    );
    if (fechaActual > fechaInicialComprobación) {
      this.translate
        .get('dashboard.eventos.notificacion.fechapasado')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.ocultarForm = false;
            setTimeout(() => {
              this.activarCheckboxClases();
              this.activarCheckboxUsuarios();
            }, 500);
          }, 1500);
          return;
        });
    }

    if (this.descripcion === undefined) {
      this.descripcion = '';
    }

    if (this.videollamada == 'si') {
      this.linkvideo =
        'https://demo.thecampus.education/' + this.userdata.usuario;
    }

    // if (this.invitados === 'clase') {
    //   if (this.clasetoda.length > 0) {
    //     this.clasetoda.push(this.userUid);
    //     var asis = new Array();
    //     var noasis = new Array();
    //     const evento = {
    //       nombre: this.nombre,
    //       descripcion: this.descripcion,
    //       clase: this.clasesSeleccionadas[0],
    //       clases: this.clasesSeleccionadas,
    //       estudiantes: this.clasetoda,
    //       fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
    //       fechainicio: this.fechainicio,
    //       horainicio: this.horainicio,
    //       fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
    //       fechafinal: this.fechafinal,
    //       horafinal: this.horafinal,
    //       creado: this.userUid,
    //       asistentes: asis,
    //       noasistentes: noasis,
    //       videoconferencia: this.linkvideo,
    //       emails: this.emails,
    //     };

    //     var fechainicio = this.fechainicio.split('-').reverse().join('-');
    //     this.eventosService.createdEvento(evento).then((evnt) => {
    //       this.notificacion = 'El evento ha sido creado';

    //       setTimeout(() => {
    //         this.notificacion = '';
    //         this.ocultarForm = false;
    //         this.router.navigate([
    //           '/dashboard/profile/' + this.userUid + '/miseventos/',
    //         ]);
    //       }, 2000);

    //       this.clasetoda.forEach((estudiante) => {
    //         const notification = {
    //           user: estudiante,
    //           texto:
    //             'Ha sido invitado al evento ' +
    //             this.nombre +
    //             '. El dia ' +
    //             fechainicio +
    //             ', hora: ' +
    //             this.horainicio,
    //           url: '/dashboard/profile/' + estudiante + '/miseventos',
    //           verlo: estudiante,
    //           fecha: new Date(),
    //           event_id: evnt.id,
    //           emisor: this.userUid,
    //         };
    //         this.notificacionesService.createdNotificacion(notification);

    //         if (this.userUid !== estudiante) {
    //           var linkEmail: string;
    //           var textEmail: string;
    //           var textboton: string;
    //           if (this.linkvideo != '') {
    //             linkEmail = this.linkvideo;
    //             textEmail =
    //               'Ha sido invitado a la videoconferencia ' +
    //               this.nombre +
    //               '. El dia ' +
    //               fechainicio +
    //               ', hora: ' +
    //               this.horainicio;
    //             textboton = 'Ir a la conferencia';
    //           } else {
    //             linkEmail = 'https://demo.thecampus.education/';
    //             textEmail =
    //               'Ha sido invitado al evento ' +
    //               this.nombre +
    //               '. El dia ' +
    //               fechainicio +
    //               ', hora: ' +
    //               this.horainicio;
    //             textboton = 'Iniciar sesión';
    //           }
    //           // this.userService.getUserById(estudiante).subscribe((tuser) => {
    //           //   this.estudianteDatos = tuser[0].payload.doc.data();
    //           //   const callable = this.functions.httpsCallable('sendEmail');
    //           //   const obs = callable({
    //           //     email: this.estudianteDatos.email,
    //           //     asunto: 'Ha sido invitado a un evento en The Campus',
    //           //     title: 'Ha sido invitado a un evento',
    //           //     text: textEmail,
    //           //     link: linkEmail,
    //           //     linktext: textboton,
    //           //   });
    //           //   obs.subscribe(async (res) => {});
    //           // });
    //         }
    //       });
    //       var linkEmail: string;
    //       var textEmail: string;
    //       var textboton: string;
    //       if (this.linkvideo != '') {
    //         linkEmail = this.linkvideo;
    //         textEmail =
    //           'Ha sido invitado a la videoconferencia ' +
    //           this.nombre +
    //           '. El dia ' +
    //           fechainicio +
    //           ', hora: ' +
    //           this.horainicio;
    //         textboton = 'Ir a la conferencia';
    //       } else {
    //         linkEmail = 'https://thecampus.education/';
    //         textEmail =
    //           'Ha sido invitado al evento ' +
    //           this.nombre +
    //           '. El dia ' +
    //           fechainicio +
    //           ', hora: ' +
    //           this.horainicio;
    //         textboton = 'Iniciar sesión';
    //       }
    //       this.emails.forEach((element) => {
    //         const callable = this.functions.httpsCallable('sendEmail');
    //         const obs = callable({
    //           email: element,
    //           asunto: 'Ha sido invitado a una videoconferencia',
    //           title: 'Ha sido invitado a una videoconferencia',
    //           text: textEmail,
    //           link: linkEmail,
    //           linktext: 'Ir a la videoconferencia',
    //         });
    //         obs.subscribe(async (res) => {});
    //       });
    //     });
    //   }
    // } else {
    this.estudiantes.push(this.userUid);
    var asis = new Array();
    var noasis = new Array();
    var clase = [];
    if (this.clasesSeleccionadas.length > 0) {
      clase = this.clasesSeleccionadas[0];
    }
    const evento = {
      nombre: this.nombre.toLowerCase(),
      descripcion: this.descripcion,
      clase: clase,
      clases: this.clasesSeleccionadas,
      estudiantes: this.estudiantes,
      fecha_inicio: new Date(this.fechainicio + ' ' + this.horainicio),
      fechainicio: this.fechainicio,
      horainicio: this.horainicio,
      fecha_final: new Date(this.fechafinal + ' ' + this.horafinal),
      fechafinal: this.fechafinal,
      horafinal: this.horafinal,
      creado: this.userUid,
      asistentes: asis,
      noasistentes: noasis,
      videoconferencia: this.linkvideo,
      emails: this.emails,
    };
    // var fechainicio = this.fechainicio.split('-').reverse().join('-');
    var fecha = new Date(this.fechainicio + ' ' + this.horainicio);
    var fechainicio = this.datepipe.transform(fecha, 'MM/dd/yyyy, HH:mm');
    var fechafin = new Date(this.fechafinal + ' ' + this.horafinal);

    this.eventosService.createdEvento(evento).then((evnt) => {
      this.notificacion = '';
      this.translate
        .get('dashboard.eventos.notificacion.creado')
        .subscribe((data) => {
          this.notificacion = data;
          setTimeout(() => {
            this.router.navigate([
              '/dashboard/profile/' + this.userUid + '/miseventos/',
            ]);
          }, 2000);
        });

      this.estudiantes.forEach((estudiante) => {
        const notification = {
          user: estudiante,
          texto: '📅 ' + evento.nombre,
          url: '/dashboard/profile/' + estudiante + '/miseventos',
          verlo: estudiante,
          fecha: new Date(),
          event_id: evnt.id,
          emisor: this.userUid,
          mensaje: false,
        };
        this.notificacionesService.createdNotificacion(notification);
        if (this.userUid !== estudiante) {
          this.sendNotification(estudiante, this.nombre);
          // this.userService.getUserById(estudiante).subscribe((tuser) => {
          //   this.estudianteDatos = tuser[0].payload.doc.data();
          //   const callable = this.functions.httpsCallable('sendEmail');
          //   const obs = callable({
          //     email: this.estudianteDatos.email,
          //     asunto: 'Ha sido invitado a un evento en The Campus',
          //     title: 'Ha sido invitado a un evento',
          //     text: textEmail,
          //     link: linkEmail,
          //     linktext: textboton,
          //   });
          //   obs.subscribe(async (res) => {});
          // });
        }
      });
      let linkEmail = '';
      let api = 'sendEmail';
      if (this.linkvideo != '') {
        switch (len) {
          case 'en':
            api = 'sendEmailEn';
            break;
          case 'es':
            api = 'sendEmail';
            break;
          case 'ar':
            api = 'sendEmailEn';
            break;
          default:
            api = 'sendEmailEn';
            break;
        }
      } else {
        switch (len) {
          case 'en':
            api = 'sendEmailNoVideoEn';
            break;
          case 'es':
            api = 'sendEmailNoVideo';
            break;
          case 'ar':
            api = 'sendEmailNoVideoEn';
            break;
          default:
            api = 'sendEmailNoVideoEn';
            break;
        }
      }

      // if (this.linkvideo != '') {
      //   linkEmail = this.linkvideo;
      //   // textEmail = 'Ha sido invitado a la videoconferencia: ' + this.nombre;
      //   // textboton = 'Ir a la conferencia';
      // } else {
      //   linkEmail = 'https://thecampus.education/';
      //   // textEmail = 'Ha sido invitado al evento: ' + this.nombre;

      //   // textboton = 'Iniciar sesión';
      // }

      var titulofichero = quitarCaracteresEspeciales(this.nombre);
      var fechaICS = new Date(this.fechainicio + ' ' + this.horainicio);
      var fechafinalICS = new Date(this.fechafinal + ' ' + this.horafinal);

      var eventParse = {
        titulo: titulofichero,
        fecha: fechaICS
          .toISOString()
          .replace('-', '')
          .replace('-', '')
          .replace(':', '')
          .replace(':', '')
          .replace('.', ''),
        fechafin: fechafinalICS
          .toISOString()
          .replace('-', '')
          .replace('-', '')
          .replace(':', '')
          .replace(':', '')
          .replace('.', ''),
        descripcion: this.descripcion,
        organizador: this.userdata.email,
      };

      var fechaemail = this.datepipe.transform(fecha, 'dd/MM/yyyy');
      var fechaFinEmail = this.datepipe.transform(fechafin, 'dd/MM/yyyy');

      this.emails.forEach((element) => {
        const callable = this.functions.httpsCallable(api);
        const obs = callable({
          email: element,
          titulo: this.nombre,
          fecha: fechaemail,
          hora: this.horainicio,
          fechafin: fechaFinEmail,
          horafin: this.horafinal,
          link: linkEmail,
          event: eventParse,
          nombre: this.userdata.nombres + ' ' + this.userdata.apellidos,
        });
        obs.subscribe(async (res) => {});
      });
    });
    // }
  }
  getUserId(event, uid) {
    if (event.target.checked) {
      this.estudiantes.push(uid);
    } else {
      this.estudiantes = this.estudiantes.filter((m) => m != uid);
    }
  }
  addEmail() {
    if (this.validateEmail(this.email)) {
      if (!this.emails.includes(this.email)) {
        this.emails.push(this.email);
        this.email = '';
      }
    } else {
      this.translate.get('dashboard.eventos.formatoemail').subscribe((data) => {
        this.formatoEmailMensaje = data;
        setTimeout(() => {
          this.formatoEmailMensaje = '';
        }, 2000);
      });
    }
  }

  quitarEmail(email) {
    var posicion = this.emails.indexOf(email);
    this.emails.splice(posicion, 1);
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  selectClase(event, uid) {
    if (event.target.checked) {
      this.ninguna = false;
      this.clasesSeleccionadas.push(uid);
    } else {
      this.clasesSeleccionadas = this.clasesSeleccionadas.filter(
        (m) => m != uid
      );
      // this.quitarUsuarios(uid);
      this.estudiantes = [];
    }
    this.usuariosClasesSeleccionadas = [];

    setTimeout(() => {
      this.recogerUsuarios();
    }, 500);
  }

  ningunaClase(event) {
    if (event.target.checked) {
      this.ninguna = true;
      this.clasesSeleccionadas = [];
      this.usuariosClasesSeleccionadas = [];
      this.videollamada = 'si';
    } else {
      this.ninguna = false;
    }
  }

  recogerUsuarios() {
    this.usuariosClasesSeleccionadas = [];
    this.clasetoda = [];
    this.estudiantes = [];
    for (const clase of this.clasesSeleccionadas) {
      this.userService.getUsersbyClass(clase).subscribe((usuarios) => {
        for (const usuario of usuarios) {
          this.usuariosClasesSeleccionadas =
            this.usuariosClasesSeleccionadas.filter(
              (m) => m.payload.doc.id != usuario.payload.doc.id
            );
          this.usuariosClasesSeleccionadas.push(usuario);
          this.clasetoda = this.clasetoda.filter(
            (m) => m != usuario.payload.doc.data()['uid']
          );
          this.clasetoda.push(usuario.payload.doc.data()['uid']);
        }
        this.usuariosClasesSeleccionadas =
          this.usuariosClasesSeleccionadas.filter(
            (m) => m.payload.doc.data().uid != this.userUid
          );
        this.clasetoda = this.clasetoda.filter((m) => m != this.userUid);
        if (this.invitados == 'clase') {
          this.estudiantes = this.clasetoda;
        } else {
          this.estudiantes = [];
        }
      });
    }
  }

  activarCheckboxClases() {
    if (this.clasesSeleccionadas.length == 0) {
      var a = document.querySelector('input[name=' + this.clase + ']');
      a['checked'] = true;
    } else {
      this.clasesSeleccionadas.forEach((clase) => {
        var a = document.querySelector('input[name=' + clase + ']');
        a['checked'] = true;
      });
    }
    // var a = document.querySelector("input[name='ab6lOfAWaSY3nQwUHCQtbKDJBWx1']")
    // a['checked']=true;
    // console.log(a)
  }

  activarCheckboxUsuarios() {
    setTimeout(() => {
      this.estudiantes.forEach((usuario) => {
        var a = document.querySelector('input[name=' + usuario + ']');
        a['checked'] = true;
      });
    }, 1000);

    // var a = document.querySelector("input[name='ab6lOfAWaSY3nQwUHCQtbKDJBWx1']")
    // a['checked']=true;
    // console.log(a)
  }

  sendNotification(usuario, mensaje) {
    var dataTrans = {
      uid: usuario,
      nombre: this.userdata['nombres'] + ' ' + this.userdata['apellidos'],
      texto: '📅 ' + mensaje,
    };
    var jsonbody = JSON.stringify(dataTrans);
    const url =
      'https://us-central1-the-campus-56b06.cloudfunctions.net/app/sendNotification';
    fetch(url, {
      method: 'POST',
      body: jsonbody,
    }).then(() => {});
  }

  // quitarUsuarios(claseuid){
  //   this.userService.getUsersbyClass(claseuid).subscribe(usuarios=>{
  //     for (const usuario of usuarios) {
  //       this.estudiantes = this.estudiantes.filter((m) => m != usuario.payload.doc.data()['uid']);
  //     }
  //   })
  // }
}

function quitarCaracteresEspeciales(titulo: string) {
  return titulo
    .split('.')
    .join('_')
    .split('.')
    .join('_')
    .split('/')
    .join('_')
    .split(':')
    .join('_')
    .split('*')
    .join('_')
    .split('?')
    .join('_')
    .split('"')
    .join('_')
    .split('<')
    .join('_')
    .split('>')
    .join('_')
    .split('|')
    .join('_')
    .split(' ')
    .join('_');
}
