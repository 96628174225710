import { Component, OnInit } from '@angular/core';
import { IachatService } from 'src/app/services/iachat.service';

@Component({
  selector: 'app-iachat',
  templateUrl: './iachat.component.html',
  styleUrls: ['./iachat.component.css'],
})
export class IachatComponent implements OnInit {
  messages: { sender: string; text: string; timestamp: number }[] = [];
  newMessage: string = '';
  conversationId: string = '';
  constructor(private iachatService: IachatService) {}

  ngOnInit(): void {
    this.conversationId = 'conversacion-1'; // Identificador único para la conversación
    // this.iachatService
    //   .loadMessages(this.conversationId)
    //   .subscribe((messages) => {
    //     this.messages = messages as {
    //       sender: string;
    //       text: string;
    //       timestamp: number;
    //     }[];
    //   });
  }

  sendMessage() {
    // Crear el mensaje del usuario
    const userMessage = {
      sender: 'user',
      text: this.newMessage,
      timestamp: Date.now(),
    };

    // Guardar el mensaje del usuario en Firestore
    this.iachatService
      .saveMessage(this.conversationId, userMessage)
      .then(() => {
        console.log('Mensaje del usuario guardado');
      });

    // Llamar a la IA y manejar la respuesta
    this.iachatService
      .getAIResponse(this.newMessage)
      .subscribe((response: any) => {
        const aiReply = {
          sender: 'ai',
          text: response.choices[0].text.trim(),
          timestamp: Date.now(),
        };

        // Agregar el mensaje de la IA a la conversación
        this.messages.push(aiReply);

        // Guardar la respuesta de la IA en Firestore
        this.iachatService
          .saveMessage(this.conversationId, aiReply)
          .then(() => {
            console.log('Respuesta de la IA guardada');
          });
      });

    // Agregar el mensaje del usuario a la conversación
    this.messages.push(userMessage);
    this.newMessage = '';
  }
}
