<div class="contenedor">
  <div class="documentos" *ngIf="!mostrarChat">
    <section class="basicbox">
      <h2>Documentos</h2>
      <div class="contenedordocumentos">
        <div class="documento" *ngFor="let documento of documentos">
          <div class="nombre">
            <h3>{{ documento.nombre }}</h3>
          </div>
          <div class="activado">
            <label class="switch">
              <input
                type="checkbox"
                [(ngModel)]="documento.activado"
                (change)="actualizarEstado(documento)"
              />
              <span class="slider"></span>
            </label>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="userchat" [class.fullscreen]="mostrarChat">
    <section class="basicbox chatmessages">
      <div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">
        <ng-container *ngFor="let messages of chatIa">
          <!-- message -->
          <div class="message">
            <div
              class="avatar"
              *ngIf="messages?.payload.doc.data().sender == 'IA'"
            >
              ><img src="assets/img/defaultavatar.jpg" alt="" />

              <!--<div class="status"></div>-->
            </div>
            <div
              class="avatar"
              *ngIf="messages?.payload.doc.data().sender != 'IA'"
            >
              <img
                *ngIf="
                  usuario?.avatar;
                  else defaultavatar
                "
                src="{{
                   usuario?.avatar
                }}"
                alt="{{
                   usuario?.nombres
                }} {{
                   usuario?.apellidos
                }}"
              />
              <ng-template #defaultavatar
                ><img src="assets/img/defaultavatar.jpg" alt=""
              /></ng-template>

              <!--<div class="status"></div>-->
            </div>
            <div class="messagedata" *ngIf="messages?.payload.doc.data().sender == 'IA'">
              <div class="name">
                Nombre IA
                Apellidos IA
              </div>
              <div class="date">
                {{
                  messages?.payload.doc.data().fecha.toDate()
                    | date : "dd/MM/y - HH:mm"
                }}h
              </div>
              <div class="themessage">
                <p>{{ messages?.payload.doc.data().message }}</p>
              </div>
            </div>
            <div class="messagedata" *ngIf="messages?.payload.doc.data().sender != 'IA'">
              <div class="name">
                {{  usuario?.nombres }}
                {{  usuario?.apellidos }}
              </div>
              <div class="date">
                {{
                  messages?.payload.doc.data().fecha.toDate()
                    | date : "dd/MM/y - HH:mm"
                }}h
              </div>
              <div class="themessage">
                <p>{{ messages?.payload.doc.data().message }}</p>
              </div>
            </div>
          </div>
          <!-- /message -->
        </ng-container>
      </div>
      <div class="sendbox">
        <input
          class="messagebox"
          type="text"
          placeholder="{{ 'dashboard.mensajes.escribirmensaje' | translate }}"
          [(ngModel)]="chatmessage"
          (keyup.enter)="sendmessage()"
        />
        <input
          class="sendmessage"
          type="submit"
          value="{{ 'dashboard.mensajes.enviarmensaje' | translate }}"
          (click)="sendmessage()"
        />
      </div>
    </section>
  </div>

  <div class="ventanas">
    <section class="basicbox ventanasbox">
      <button (click)="toggleView()" class="buttonExpandir">
        {{ mostrarChat ? "Volver" : "Ampliar Chat" }}
      </button>
    </section>
  </div>
</div>
