import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IachatService {
  private apiUrl = 'https://api.openai.com/v1/completions'; // Cambia según el proveedor
  private apiKey = 'TU_API_KEY'; // Agrega tu clave de API aquí
  constructor(private firestore: AngularFirestore, private http: HttpClient) {}

  // Llama a la API de IA para obtener la respuesta
  getAIResponse(prompt: string) {
    const body = {
      model: 'text-davinci-003', // Cambia al modelo que desees usar
      prompt: prompt,
      max_tokens: 150,
    };
    return this.http.post(this.apiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.apiKey}`,
      },
    });
  }

  saveMessage(conversationId: string, message) {
    // Crea o actualiza la conversación en Firestore
    return this.firestore
      .collection('iachat')
      .doc(conversationId)
      .collection('mensajes')
      .add(message);
  }

  loadMessages(conversationId: string) {
    return this.firestore
      .collection('conversaciones')
      .doc(conversationId)
      .collection('mensajes', (ref) => ref.orderBy('timestamp'))
      .valueChanges();
  }
}
