import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IaFilesService } from 'src/app/services/ia-files.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-iafiles',
  templateUrl: './iafiles.component.html',
  styleUrls: ['./iafiles.component.css'],
})
export class IafilesComponent implements OnInit {
  mainuserid: string;

  chatmessage = '';
  datecu: Date;
  elotrousaurio: any;

  urluid: string;
  grupodechats = [];

  /*nuevo */
  chatorimessa = [];

  chatusers = [];

  mainuseremail: any;
  usuario: any;
  mostrarChat = false; // Estado inicial
  chatIa = [];

  documentos = [
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento1',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento2',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento3',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento4',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento5',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento6',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento7',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento8',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento9',
      tipo: 'pdf',
      activado: true,
    },
    {
      localizacion: 'https://firebasestorage.googleapis.com',
      nombre: 'documento10',
      tipo: 'pdf',
      activado: true,
    },
  ];

  constructor(
    //  private activatedRoute: ActivatedRoute,
    //  private notificacionesService: NotificacionesService,
    private userService: UserService,
    //  private chatService: ChatService,
    private authenticationService: AuthenticationService,
    private iaFilesService: IaFilesService,
    private translate: TranslateService //  private _numeroAlertas: NumeroalertasService, //  private _alertService: AlertasService
  ) {}

  ngOnInit(): void {
    this.authenticationService.getStatus().subscribe(
      (status) => {
        this.mainuserid = status.uid;
        this.mainuseremail = status.email;

        let suscription = this.userService
          .getUserById(this.mainuserid)
          .subscribe((user) => {
            this.usuario = user[0].payload.doc.data();
            this.cargarmensajes();
          });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ngOnDestroy(): void {}

  sendmessage() {
    let len = this.translate.getDefaultLang();

    if (this.chatmessage != '') {
      this.datecu = new Date();
      const message = {
        sender: this.mainuserid,
        message: this.chatmessage,
        fecha: this.datecu,
        nuevo: true,
      };
      this.chatmessage = '';

      const ids = [this.mainuserid, this.elotrousaurio].sort();
      this.iaFilesService
        .sendmessage(this.mainuserid, message)
        .then((datames) => {
          // this.chatmessage = '';
          this.cargarmensajes();
          // this.analisis(message);

          // var notification = {
          //   user: this.elotrousaurio,
          //   texto: '🗨️ ' + message.message,
          //   url: '/dashboard/mensajes/chat/' + chatid,
          //   verlo: this.elotrousaurio,
          //   fecha: new Date(),
          //   emisor: this.mainuserid,
          //   mensaje: true,
          // };
          // this.notificacionesService.createdNotificacion(notification);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  cargarmensajes() {
    this.iaFilesService.getMessages(this.mainuserid).subscribe((messages) => {
      console.log(messages);
      this.chatIa = messages;
    });
  }

  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }

  actualizarEstado(documento: any): void {
    console.log(
      `El estado de "${documento.nombre}" ahora es:`,
      documento.activado
    );
  }

  toggleView(): void {
    this.mostrarChat = !this.mostrarChat; // Alterna entre true y false
  }
}
