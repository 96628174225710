import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessionActive = false;
  private sessionStart: number = 0;
  private userId: string | null = null; // ID del usuario

  constructor(
    private analytics: AngularFireAnalytics,
    private afAuth: AngularFireAuth
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = null;
      }
    });
  }

  startSession() {
    
    // if (!this.sessionActive && this.userId) {
    //   this.sessionActive = true;
    //   this.sessionStart = Date.now();

    //   // Registro del evento en Firebase Analytics
    //   this.analytics.logEvent('session_start', {
    //     userId: this.userId,
    //     startTime: this.sessionStart,
    //   });
    //   console.log('Inicio de sesión registrado para el usuario:', this.userId);
    // }
  }

  endSession() {
    // if (this.sessionActive && this.userId) {
    //   const sessionEnd = Date.now();
    //   const sessionDuration = sessionEnd - this.sessionStart;

    //   // Registro del evento de fin en Firebase Analytics
    //   this.analytics.logEvent('session_end', {
    //     userId: this.userId,
    //     endTime: sessionEnd,
    //     duration: sessionDuration,
    //   });
    //   console.log('Fin de sesión registrado para el usuario:', this.userId);

    //   this.sessionActive = false;
    //   this.sessionStart = 0;
    // }
  }
}
