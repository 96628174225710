import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  uid = null;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore
  ) {
    this.angularFireAuth.authState.subscribe((user) => {
      if (user) {
        this.uid = user.uid;
        //console.log(user.uid);
      } else {
        // Empty the value when user signs out
        this.uid = null;
      }
    });
  }

  loginWithEmail(email: string, password: string) {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  loginWithSAML(provider) {
    return this.angularFireAuth.signInWithPopup(provider);
  }

  registerWithEmail(email: string, password: string) {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  getStatus() {
    return this.angularFireAuth.authState;
  }

  logOut() {
    return this.angularFireAuth.signOut();
  }

  getCurrentUserID() {
    return this.uid;
  }

  isUserAdmin(uid) {
    return this.angularFirestore
      .collection('users', (ref) => ref.where('uid', '==', uid))
      .valueChanges();
  }
  isUserAdminBueno(uid) {
    return this.angularFirestore
      .collection('users', (ref) =>
        ref.where('uid', '==', uid).where('rol', '==', 'admin')
      )
      .valueChanges();
  }
  isUserBueno(uid) {
    return this.angularFirestore
      .collection('users', (ref) => ref.where('uid', '==', uid))
      .valueChanges();
  }

  isAuth() {
    return this.angularFireAuth.authState.pipe(map((auth) => auth));
  }

  updatepass(user) {
    return user
      .updatePassword('canchilas')
      .then(function () {
        // Update successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  async sendEmailverification() {
    (await this.angularFireAuth.currentUser).sendEmailVerification();
  }

  async resetemail(email) {
    await this.angularFireAuth.sendPasswordResetEmail(email);
  }
}
