import { AngularFireStorageModule } from '@angular/fire/storage';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard.component';
import { MenuComponent } from './menu/menu.component';
import { NgModule, LOCALE_ID} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MensajesComponent } from './mensajes/mensajes.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClaseComponent } from './clase/clase.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { HeaderComponent } from './header/header.component';
import { DashboardHomeComponent } from './home/home.component';
import { HiloComponent } from './mensajes/hilo/hilo.component';
import { AjustesComponent } from './profile/ajustes/ajustes.component';
import { PassComponent } from './profile/pass/pass.component';
import { FotoComponent } from './profile/foto/foto.component';
import { SearchPipe } from '../pipes/search.pipe';
import { Search2Pipe } from '../pipes/search2.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { MisgruposComponent } from './misgrupos/misgrupos.component';
import { MiseventosComponent } from './profile/eventos/miseventos/miseventos.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule } from 'angular-calendar';
import { DateAdapter } from 'angular-calendar';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { EventosComponent } from './profile/eventos/eventos.component';
registerLocaleData(localeEs);
import {NgxPaginationModule} from 'ngx-pagination';
import { CreargrupoComponent } from './misgrupos/creargrupo/creargrupo.component';
import { EditarclaseComponent } from './clase/editarclase/editarclase.component';
import { EditareventoComponent } from './profile/eventos/editarevento/editarevento.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { PresenciaComponent } from './presencia/presencia.component';
import { AppsInternasComponent } from './home/apps-internas/apps-internas.component';
import { UserboxComponent } from './home/userbox/userbox.component';
import { UserboxChatComponent } from './mensajes/userbox-chat/userbox-chat.component';
import { UserboxClaseComponent } from './clase/userbox-clase/userbox-clase.component';
import { AlertasComponent } from './alertas/alertas.component';
import { AlertaComponent } from './alertas/alerta/alerta.component';
import { AlertaboxComponent } from './alertas/alertabox/alertabox.component';
import { ReactiveFormsModule } from "@angular/forms";
import { EventoBoxComponent } from './profile/eventos/miseventos/evento-box/evento-box.component';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { MisalaComponent } from './profile/misala/misala.component';
import { OrderbyPipe } from '../pipes/orderby.pipe';
import { FilterarchivoPipe } from '../pipes/filterarchivo.pipe';
import { BreadcrumbactualPipe } from '../pipes/breadcrumbactual.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { EliminarusuariosComponent } from './eliminarusuarios/eliminarusuarios.component';
import { CursosComponent } from './cursos/cursos.component';
import { CrearcursosComponent } from './cursos/crearcursos/crearcursos.component';
import { CursoboxComponent } from './cursos/cursobox/cursobox.component';
import { ManualComponent } from './manual/manual.component';
import { NotificacionComponent } from './notificaciones/notificacion/notificacion.component';
import { PadresComponent } from './padres/padres.component';
import { FilterAppPipe } from '../pipes/filter-app.pipe';
import { IachatComponent } from './iachat/iachat.component';
import { IafilesComponent } from './iafiles/iafiles.component';
import { ClasesboxDashboardComponent } from './clasesbox-dashboard/clasesbox-dashboard.component';



@NgModule({
  declarations: [
    MenuComponent,
    DashboardComponent,
    ProfileComponent,
    MensajesComponent,
    ClaseComponent,
    ArchivosComponent,
    NotificacionesComponent,
    HeaderComponent,
    DashboardHomeComponent,
    HiloComponent,
    AjustesComponent,
    PassComponent,
    FotoComponent,
    SearchPipe,
    Search2Pipe,
    FilterAppPipe,
    SafePipe,
    OrderbyPipe,
    FilterarchivoPipe,
    BreadcrumbactualPipe,
    MisgruposComponent,
    MiseventosComponent,
    CalendarComponent,
    EventosComponent,
    CreargrupoComponent,
    EditarclaseComponent,
    EditareventoComponent,
    PresenciaComponent,
    AppsInternasComponent,
    UserboxComponent,
    UserboxChatComponent,
    UserboxClaseComponent,
    AlertasComponent,
    AlertaComponent,
    AlertaboxComponent,
    EventoBoxComponent,
    MisalaComponent,
    EliminarusuariosComponent,
    CursosComponent,
    CrearcursosComponent,
    CursoboxComponent,
    ManualComponent,
    NotificacionComponent,
    PadresComponent,
    IachatComponent,
    IafilesComponent,
    ClasesboxDashboardComponent,
    
    ],
  imports: [
    TimepickerModule,
    DatetimePopupModule,
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ImageCropperModule,
    AngularFireStorageModule,
    NgbModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxPaginationModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
    ReactiveFormsModule

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES'},
  ]
})
export class DashboardModule { }
